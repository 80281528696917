import type { ItemFeature } from '@sbt-web/networking';
import { FeatureURI } from '@sbt-web/networking';

// Whether or not a feature is allowed to be shown
const AllowedFeatures: Partial<Record<FeatureURI, boolean>> = {
  [FeatureURI.AirConditioning]: true,
  [FeatureURI.AllServicings]: true,
  [FeatureURI.AnimalType]: true,
  [FeatureURI.AudiovideoType]: true,
  [FeatureURI.AvailableNow]: true,
  [FeatureURI.Balcony]: true,
  [FeatureURI.Bathrooms]: true,
  [FeatureURI.BicycleType]: true,
  [FeatureURI.Bike]: true,
  [FeatureURI.BookType]: true,
  [FeatureURI.BuildingCondition]: true,
  [FeatureURI.Car]: true,
  [FeatureURI.CaravanType]: true,
  [FeatureURI.CarType]: true,
  [FeatureURI.ChildrenAge]: true,
  [FeatureURI.ChildrenType]: true,
  [FeatureURI.ClothingGender]: true,
  [FeatureURI.ClothingNumber]: true,
  [FeatureURI.ClothingType]: true,
  [FeatureURI.Color]: true,
  [FeatureURI.ComputerType]: true,
  [FeatureURI.Consumption]: true,
  [FeatureURI.ConsumptionSearchable]: true,
  [FeatureURI.ContractType]: true,
  [FeatureURI.CubicCapacity]: true,
  [FeatureURI.Damaged]: true,
  [FeatureURI.Degree]: true,
  [FeatureURI.Doors]: true,
  [FeatureURI.DriveWheel]: true,
  [FeatureURI.Elevator]: true,
  [FeatureURI.EnergyClass]: true,
  [FeatureURI.Floor]: true,
  [FeatureURI.Fuel]: true,
  [FeatureURI.Furnished]: true,
  [FeatureURI.Garden]: true,
  [FeatureURI.Gearbox]: true,
  [FeatureURI.Gender]: true,
  [FeatureURI.Heating]: true,
  [FeatureURI.HobbyType]: true,
  [FeatureURI.HorsePower]: true,
  [FeatureURI.ItemCondition]: true,
  [FeatureURI.JobCategory]: true,
  [FeatureURI.LastFloor]: true,
  [FeatureURI.MileageScalar]: true,
  [FeatureURI.MonthlyRent]: true,
  [FeatureURI.MotorbicycleType]: true,
  [FeatureURI.MultiLevel]: true,
  [FeatureURI.OfficeType]: true,
  [FeatureURI.PaintType]: true,
  [FeatureURI.Parking]: true,
  [FeatureURI.PhoneType]: true,
  [FeatureURI.Pollution]: true,
  [FeatureURI.Reception]: true,
  [FeatureURI.RegisterDate]: true,
  [FeatureURI.Room]: true,
  [FeatureURI.Rooms]: true,
  [FeatureURI.RoomType]: true,
  [FeatureURI.Seats]: true,
  [FeatureURI.Service]: true,
  [FeatureURI.ShipLength]: true,
  [FeatureURI.ShipType]: true,
  [FeatureURI.Size]: true,
  [FeatureURI.SmokeFree]: true,
  [FeatureURI.Smoker]: true,
  [FeatureURI.SportType]: true,
  [FeatureURI.TotalOwners]: true,
  [FeatureURI.VatDeductible]: true,
  [FeatureURI.VehicleLength]: true,
  [FeatureURI.VehicleStatus]: true,
  [FeatureURI.VehicleType]: true,
  [FeatureURI.WarrantyMonths]: true,
  [FeatureURI.WorkHour]: true,
  [FeatureURI.WorkLevel]: true,
};

/**
 * Returns `true` only if the feature exists and the first value key is 1
 */
export const evalBoolFeature = (feat?: ItemFeature): boolean =>
  feat?.values?.[0]?.key === '1';

export const evalNumberFeature = (feat?: ItemFeature): number | null =>
  feat?.values?.[0]?.key ? parseFloat(feat?.values?.[0].key) : null;

export function filterDetailFeatures(features: ItemFeature[]): ItemFeature[] {
  if ((features || []).length === 0) return [];

  const featuresToDisplay = features.filter(canBeShownInDetail);

  // TO-DO: remove this once every ad has a registered date
  // this is needed at the moment to work around the fact that
  // some ads might have a complete register date (e.g. 2019/02)
  // and some other might be limited to the registration year,
  // which we fall back to
  let feature = getFeature(features, FeatureURI.Year);
  if (
    !hasFeature(features, FeatureURI.RegisterDate) &&
    hasFeature(features, FeatureURI.Year) &&
    feature !== undefined
  ) {
    featuresToDisplay.push(feature);
  }

  // TO-DO: remove this once every ad has a punctual km data
  // this is needed at the moment to work around the fact that
  // some ads might have old mileage range
  // and some other might have the punctual km data,
  // which we fall back to
  feature = getFeature(features, FeatureURI.Mileage);
  if (
    !hasFeature(features, FeatureURI.MileageScalar) &&
    hasFeature(features, FeatureURI.Mileage) &&
    feature !== undefined
  ) {
    featuresToDisplay.push(feature);
  }

  return featuresToDisplay;
}

function canBeShownInDetail(feat?: ItemFeature): boolean {
  if (feat) {
    return AllowedFeatures[feat.uri as keyof typeof AllowedFeatures] === true;
  } else {
    return false;
  }
}

const hasFeature = (features: ItemFeature[], uri: FeatureURI): boolean =>
  features.some((f) => f?.uri === uri);

const getFeature = (
  features: ItemFeature[],
  uri: FeatureURI
): ItemFeature | undefined => features.filter((f) => f?.uri === uri)[0];
