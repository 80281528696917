import {
  AdSenseVariations,
  Channels,
  getChannel,
  LISTING_DESKTOP,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'desktop-middle-lp-tutti-2',
  Jobs = 'desktop-middle-lp-jobs-2',
  Motor = 'desktop-middle-lp-motor-2',
  Market = 'desktop-middle-lp-market-2',
  RealEstate = 'desktop-middle-lp-realestate-2',
}

const desktopMiddle2Default: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '6572691774',
    channel: getChannel([
      ...LISTING_DESKTOP,
      Channels.Position.Middle,
      Channels.Insertion.DesktopTuttiListingMiddle,
      Channels.Insertion.DesktopTuttiListingMiddle2,
      'DESKTOP-MIDDLE-LP-TUTTI-2-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle2Job: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '2174373554',
    channel: getChannel([
      ...LISTING_DESKTOP,
      Channels.Position.Middle,
      Channels.Vertical.Job,
      Channels.Insertion.DesktopJobsListingMiddle,
      Channels.Insertion.DesktopJobsListingMiddle2,
      'DESKTOP-MIDDLE-LP-JOBS-2-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle2Motor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '3431554769',
    channel: getChannel([
      ...LISTING_DESKTOP,
      Channels.Position.Middle,
      Channels.Vertical.Motors,
      Channels.Insertion.DesktopMotoriListingMiddle,
      Channels.Insertion.DesktopMotoriListingMiddle2,
      'DESKTOP-MIDDLE-LP-MOTORS-2-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle2Market: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '7809855008',
    channel: getChannel([
      ...LISTING_DESKTOP,
      Channels.Position.Middle,
      Channels.Vertical.Market,
      Channels.Insertion.DesktopMarketListingMiddle,
      Channels.Insertion.DesktopMarketListingMiddle2,
      'DESKTOP-MIDDLE-LP-MARKET-2-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle2RealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '4744637072',
    channel: getChannel([
      ...LISTING_DESKTOP,
      Channels.Position.Middle,
      Channels.Vertical.RealEstate,
      Channels.Insertion.DesktopRealEstateListingMiddle,
      Channels.Insertion.DesktopRealEstateListingMiddle2,
      'DESKTOP-MIDDLE-LP-REALESTATE-2-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 1 },
};

const desktopMiddle2: Array<ShortConfiguration> = [
  desktopMiddle2Default,
  desktopMiddle2Job,
  desktopMiddle2Motor,
  desktopMiddle2Market,
  desktopMiddle2RealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export {
  desktopMiddle2,
  IDs as DesktopMiddle2IDs,
  variations as DesktopMiddle2,
};
