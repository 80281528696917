import type { DataLayer, DLAd } from '@client/bridges/DataLayer';
import { getCategory, Pulse } from '@sbt-web/tracking';
import { PulseClassifiedAd } from '@tools/tracking/transformer/AdsTransformer';

type AdBlockStatus = 'active' | 'inactive';

const getConsent = async (): Promise<boolean> => {
  return new Promise((resolve) => {
    const timeout = window.setTimeout(() => resolve(false), 5000);
    window.didomiOnReady.push(() => {
      clearInterval(timeout);
      resolve(window.Didomi.getUserConsentStatusForPurpose('cookies'));
    });
  });
};

const fetchWithHead = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.ok;
  } catch {
    return false;
  }
};

const detectAdBlocker = async (): Promise<AdBlockStatus> => {
  const assetBaseUrl = process.env.NEXT_PUBLIC_ASSETS_BASE_URL;

  if (!assetBaseUrl) {
    console.error('Missing environment variable: NEXT_PUBLIC_ASSETS_BASE_URL');
    return 'inactive'; // Default to 'inactive' in case of misconfiguration
  }

  try {
    const [adsJsLoaded, prebidJsLoaded, consent] = await Promise.all([
      fetchWithHead(`${assetBaseUrl}/static/js/ads.js`),
      fetchWithHead(`${assetBaseUrl}/static/js/prebid.js`),
      getConsent(),
    ]);

    if ((!adsJsLoaded || !prebidJsLoaded) && consent) {
      return 'active';
    }
    return 'inactive';
  } catch (e) {
    console.error('Error detecting AdBlocker', e);
    return 'inactive';
  }
};

function getDataLayer(): DataLayer {
  if (!window.subito?.dataLayer?.ads) {
    throw new Error('Missing Data Layer.');
  }

  return window.subito.dataLayer;
}

let pulse: Pulse;

/**
 * A callback that is called on DataLayer.ts
 * when it is properly filled.
 */
const onDataLayerReady = function (): void {
  const pulseInstance = getOrCreatePulse();
  if (window.subito?.dataLayer?.user) {
    pulseInstance?.setActor(window.subito.dataLayer.user.id);
  }
  if (window.subito?.dataLayer?.env?.name) {
    pulseInstance?.setEnv(window.subito?.dataLayer?.env?.name);
  }
};

/**
 * Builds the Page object to attach to Pulse trackings.
 * @param pageType The type of page being tracked. Probably 'listing' or 'ad_detail'.
 */
function buildPage(pageType: string): {
  '@id': string;
  '@type': string;
  pageType: string;
} {
  return {
    '@id': `sdrn:subito:page:${pageType}`,
    '@type': 'Page',
    pageType,
  };
}

const getItem = (dataLayer: DataLayer, adUrn: string): object | undefined => {
  const adsList: Record<string, PulseClassifiedAd> = {};
  dataLayer.ads.map(
    (ad: DLAd) =>
      (adsList[ad.urn] = {
        id: ad.urn,
        publisher: {
          type: 'Account',
          id: ad.advertiser.id,
          realm: 'subito',
          accountType: ad.advertiser.type === '1' ? 'Professional' : 'Private',
        },
        type: 'ClassifiedAd',
        category: getCategory(ad.category.parent.id) || '',
        name: `${dataLayer.page.name}`,
      })
  );
  return adsList[adUrn] ? [adsList[adUrn]] : undefined;
};

function getOrCreatePulse(): Pulse | undefined {
  if (__SERVER__) {
    return undefined;
  }

  if (!pulse) {
    pulse = new Pulse();
  }
  return pulse;
}

export {
  buildPage,
  detectAdBlocker,
  getDataLayer,
  getItem,
  getOrCreatePulse,
  onDataLayerReady,
};
