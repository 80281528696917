import parsePrice from '@client/utilities/parse-price';
import {
  AdTypes,
  CategoryId,
  FeatureURI,
  type AdItem,
} from '@sbt-web/networking';
import {
  BodyText,
  ButtonLink,
  Caption,
  Headline4,
  Headline5,
  Headline6,
  ItemPrice,
} from '@sbt-web/ui';
import { getCategoryThemeById, Theme } from '@shared/helpers/Themes';
import classnames from 'classnames';
import Head from 'next/head';
import React from 'react';

import ownClasses from './410.module.scss';

interface BackupAdProps {
  ad: AdItem;
  href: string;
  adType: AdTypes;
  categoryId: CategoryId;
}

const MiniCard: React.FunctionComponent<BackupAdProps> = ({
  ad,
  href,
  adType,
  categoryId,
}) => {
  const title = ad.subject,
    photoCount = ad.images.length,
    photoBaseUrl = ad.images[0]?.cdnBaseUrl,
    price = parsePrice(ad.features[FeatureURI.Price]) || undefined;

  const { city, town } = ad.geo;
  const townString = town?.value ?? '';
  const cityString = city?.shortName ? `(${city.shortName})` : '';

  const locationString =
    townString || cityString ? `${townString} ${cityString}`.trim() : null;

  return (
    <article className={ownClasses.card}>
      <figure className={ownClasses['card-image-container']}>
        {photoBaseUrl != undefined ? (
          <img
            src={`${photoBaseUrl}?rule=card-desktop-mini-1x-auto`}
            srcSet={`${photoBaseUrl}?rule=card-desktop-mini-1x-auto 1x, ${photoBaseUrl}?rule=card-desktop-mini-2x-auto 2x, ${photoBaseUrl}?rule=card-desktop-mini-3x-auto 3x`}
            height="140"
            alt=""
            className={ownClasses['card-image']}
          />
        ) : (
          <img
            alt=""
            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/categories/${categoryId}.svg`}
            width="200"
            height="140"
            decoding="async"
          />
        )}
        {photoCount > 1 ? (
          <div className={ownClasses['card-image-count']}>
            <img
              src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/cactus/camera.svg`}
              width="16"
              height="16"
              // The alt is plural because the icon isn't shown if there's only one image.
              alt={`Questo annuncio ha ${photoCount} immagini`}
              loading="lazy"
              decoding="async"
            />
            <Caption size="small">{photoCount}</Caption>
          </div>
        ) : null}
      </figure>

      <div className={ownClasses['card-content']}>
        <Headline6>
          <a href={href} className={ownClasses['item-link']}>
            {title}
          </a>
        </Headline6>
        <Caption size="small" classes={[ownClasses['item-location']]}>
          {locationString}
        </Caption>

        <ItemPrice price={price} adType={adType} />
      </div>
    </article>
  );
};

const getIllustrationThemeByCategoryId = (categoryId: CategoryId) => {
  const theme = getCategoryThemeById(categoryId);

  switch (theme) {
    case Theme.Blue:
      return 'motori';
    case Theme.Yellow:
      return 'market';
    case Theme.Purple:
      return 'immobili';
    case Theme.Green:
      return 'lavoro';
    default:
      return 'corporate';
  }
};

interface Props {
  categoryId: CategoryId;
  categoryLabel: string;
  categoryDefaultUrl: string;
  items: AdItem[];
}

const ErrorPage: React.FunctionComponent<Props> = ({
  categoryId,
  categoryLabel,
  categoryDefaultUrl,
  items,
}) => {
  return (
    <>
      <Head>
        <title>Subito.it</title>
        <meta name="robots" content="noindex,follow" />
      </Head>

      <section
        className={classnames(
          ownClasses['top-level-section'],
          ownClasses['title-section']
        )}
      >
        <img
          src={`${
            process.env.NEXT_PUBLIC_ASSETS_BASE_URL
          }/static/errors/not-found/${getIllustrationThemeByCategoryId(
            categoryId
          )}.svg`}
          alt=""
          height="150"
          className={ownClasses['title-image']}
        />

        <div className={ownClasses['title-text']}>
          <Headline4 element="h1">Questo annuncio non esiste più</Headline4>

          <BodyText>
            È stato venduto.
            <br />
            Prova a dare un&apos;occhiata ai risultati simili sotto.
          </BodyText>

          <ButtonLink
            href="/annunci-italia/vendita/usato/"
            classes={[ownClasses['new-search-link']]}
          >
            Effettua una nuova ricerca
          </ButtonLink>
        </div>
      </section>

      <section
        className={classnames(
          ownClasses['top-level-section'],
          ownClasses['content-section']
        )}
      >
        <header className={ownClasses['content-title']}>
          <Headline5 element="h2">
            Altri risultati per {categoryLabel}
          </Headline5>

          <ButtonLink
            href={categoryDefaultUrl}
            classes={[ownClasses['specific-listing-link']]}
          >
            Vedi tutti
          </ButtonLink>
        </header>

        {items.length > 0 ? (
          <div className={ownClasses['card-grid']}>
            {items.map((item) => {
              return (
                <MiniCard
                  key={item.urn}
                  ad={item}
                  href={item.urls.default}
                  adType={item.type.key as AdTypes}
                  categoryId={categoryId}
                />
              );
            })}
          </div>
        ) : null}
      </section>
    </>
  );
};

export default ErrorPage;
export type { Props };
