import { ListingPlugin, SmartADVItem } from '@shared/models';
import { all } from '@shared/models/ListingPlugins/matchers';
import { after } from '@shared/models/ListingPlugins/position';
import {
  ADSENSE_DESKTOP_STYLE,
  DesktopGAMIds,
  GAM_DESKTOP_STYLE,
} from './commons';
import { DesktopMiddle3 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle3';
import { DesktopMiddle2 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle2';
import { DesktopMiddle1 } from '@client/components/Adv/AdSense/listing/adUnits/desktop-middle1';
import { DesktopBeforeListing } from '@client/components/Adv/AdSense/listing/adUnits/desktop-before-listing';

const desktopAdvEvery3: Array<ListingPlugin> = [
  // --- After the first Ad
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        style: ADSENSE_DESKTOP_STYLE,
        variations: DesktopBeforeListing,
        gamFallback: DesktopGAMIds.FALLBACK1,
      },
      null
    ),
    categories: all(),
    position: after(1),
  },
  // --- Then after two Ads
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        id: DesktopGAMIds.NATIVE1,
        // This placement near the fold has 200px to reduce the CLS with native
        // placement (retail media) or with big cards.
        style: { ...GAM_DESKTOP_STYLE, minHeight: '200px' },
      },
      null
    ),
    categories: all(),
    position: after(3),
  },
  // --- Then every 3 Ads
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        // In many verticals this placement has AdSense for shopping enabled.
        // So the block can take from 200px to 360px.
        style: ADSENSE_DESKTOP_STYLE,
        variations: DesktopMiddle1,
        gamFallback: DesktopGAMIds.FALLBACKMIDDLE1,
      },
      null
    ),
    categories: all(),
    position: after(6),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE2,
      },
      null
    ),
    categories: all(),
    position: after(9),
  },
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        // In many verticals this placement has AdSense for shopping enabled.
        // So the block can take from 200px to 360px.
        style: ADSENSE_DESKTOP_STYLE,
        variations: DesktopMiddle2,
        gamFallback: DesktopGAMIds.FALLBACKMIDDLE2,
      },
      null
    ),
    categories: all(),
    position: after(12),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE3,
      },
      null
    ),
    categories: all(),
    position: after(15),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE4,
      },
      null
    ),
    categories: all(),
    position: after(18),
  },
  {
    model: new SmartADVItem(
      {
        source: 'adsense',
        variations: DesktopMiddle3,
        gamFallback: DesktopGAMIds.FALLBACKMIDDLE3,
      },
      null
    ),
    categories: all(),
    position: after(21),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE5,
      },
      null
    ),
    categories: all(),
    position: after(24),
  },
  {
    model: new SmartADVItem(
      {
        source: 'gam',
        style: GAM_DESKTOP_STYLE,
        id: DesktopGAMIds.NATIVE6,
      },
      null
    ),
    categories: all(),
    position: after(27),
  },
];

export { desktopAdvEvery3 };
