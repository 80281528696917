import {
  Category,
  CategoryId,
  CategoryStore,
  isMacroCategory,
  MacroCategory,
} from '@sbt-web/networking';

export enum Theme {
  Red = 'redCorporate',
  Blue = 'blueMotori',
  Purple = 'purpleImmobili',
  Green = 'greenLavoro',
  Yellow = 'yellowMarket',
}

type MacroId =
  | CategoryId.Tutte
  | CategoryId.Motori
  | CategoryId.Immobili
  | CategoryId.LavoroServizi
  | CategoryId.Elettronica
  | CategoryId.CasaPersona
  | CategoryId.SportsHobby
  | CategoryId.Altri;

const macroThemes: { [key in MacroId]: Theme } = {
  [CategoryId.Tutte]: Theme.Red,
  [CategoryId.Motori]: Theme.Blue,
  [CategoryId.Immobili]: Theme.Purple,
  [CategoryId.LavoroServizi]: Theme.Green,
  [CategoryId.Elettronica]: Theme.Yellow,
  [CategoryId.CasaPersona]: Theme.Yellow,
  [CategoryId.SportsHobby]: Theme.Yellow,
  [CategoryId.Altri]: Theme.Yellow,
};

const getThemeForMacro = (macroCategory: MacroCategory): Theme => {
  return macroThemes[macroCategory.id as keyof typeof macroThemes] || Theme.Red;
};

/**
 * Gets the macro for a given category, returning the category itself if it's already a macro.
 * Category 0 is considered a macro.
 * @param id The CategoryId to get the MacroCategory from
 * @returns The Macro of the current category, which might be itself.
 */
export const macroFromCategoryId = (id: CategoryId): MacroCategory => {
  // This search always returns a category:
  // if the ID doesn't exist, the default result is "Tutte le categorie"
  const category = CategoryStore.getCategoryById(id);

  if (isMacroCategory(category)) {
    return category;
  } else {
    return CategoryStore.getMacroByCategory(
      category as Category
    ) as MacroCategory;
  }
};

export const getCategoryThemeById = (categoryId: CategoryId): Theme => {
  if (!categoryId) return Theme.Red;

  const macro = macroFromCategoryId(categoryId);
  return getThemeForMacro(macro);
};
