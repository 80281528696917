import { ListingPlugin, SmartADVItem } from '@shared/models';
import { all } from '@shared/models/ListingPlugins/matchers';
import { after, before } from '@shared/models/ListingPlugins/position';
import { ADSENSE_MSITE_STYLE, GAM_MSITE_STYLE, MsiteGAMIds } from './commons';
import { MsiteMiddle } from '@client/components/Adv/AdSense/listing/adUnits/msite-middle';
import { MsiteBeforeListing } from '@client/components/Adv/AdSense/listing/adUnits/msite-before-listing';

const msiteAdvEvery4: Array<ListingPlugin> = [
  {
    model: new SmartADVItem(null, {
      source: 'adsense',
      style: ADSENSE_MSITE_STYLE,
      variations: MsiteBeforeListing,
      gamFallback: MsiteGAMIds.FALLBACK1,
    }),
    categories: all(),
    position: before(1),
  },
  // --- After the first Ad
  {
    model: new SmartADVItem(null, {
      source: 'gam',
      id: MsiteGAMIds.NATIVE1,
      // Higher placement to reduce CLS issues with sizes: fluid or 1x1.
      // For the native placements 200px is the best value but we may have
      // CLS issues.
      style: { ...GAM_MSITE_STYLE, minHeight: '250px' },
    }),
    categories: all(),
    position: after(1),
  },
  // --- Then after three Ads
  {
    model: new SmartADVItem(null, {
      source: 'gam',
      id: MsiteGAMIds.BOX1,
      style: GAM_MSITE_STYLE,
    }),
    categories: all(),
    position: after(4),
  },
  // --- Then every 4 Ads
  {
    model: new SmartADVItem(null, {
      source: 'adsense',
      style: ADSENSE_MSITE_STYLE,
      variations: MsiteMiddle,
      gamFallback: MsiteGAMIds.FALLBACKMIDDLE1,
    }),
    categories: all(),
    position: after(8),
  },
  {
    model: new SmartADVItem(null, {
      source: 'gam',
      style: GAM_MSITE_STYLE,
      id: MsiteGAMIds.NATIVE2,
    }),
    categories: all(),
    position: after(12),
  },
  {
    model: new SmartADVItem(null, {
      source: 'gam',
      style: GAM_MSITE_STYLE,
      id: MsiteGAMIds.BOX2,
    }),
    categories: all(),
    position: after(16),
  },
  {
    model: new SmartADVItem(null, {
      source: 'gam',
      style: GAM_MSITE_STYLE,
      id: MsiteGAMIds.BOX3,
    }),
    categories: all(),
    position: after(20),
  },
  {
    model: new SmartADVItem(null, {
      source: 'gam',
      style: GAM_MSITE_STYLE,
      id: MsiteGAMIds.BOX4,
    }),
    categories: all(),
    position: after(24),
  },
  {
    model: new SmartADVItem(null, {
      source: 'gam',
      style: GAM_MSITE_STYLE,
      id: MsiteGAMIds.BOX5,
    }),
    categories: all(),
    position: after(28),
  },
];

export { msiteAdvEvery4 };
