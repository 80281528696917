const Channels = {
  Vertical: {
    Motors: '31',
    Market: '35',
    Job: '36',
    RealEstate: '37',
  },
  Page: {
    AdDetail: '39',
    Listing: '38',
  },
  Position: {
    Top: '40',
    Middle: '41',
    Bottom: '42',
  },
  Property: {
    Desktop: '43',
    Msite: '44',
  },
  /**
   * The specific insertion, so that the performance of individual placements
   * can be tracked.
   */
  Insertion: {
    DesktopTuttiListingTop: '100',
    DesktopMotoriListingTop: '110',
    DesktopMarketListingTop: '120',
    DesktopRealEstateListingTop: '130',
    DesktopJobsListingTop: '140',
    DesktopTuttiListingMiddle: '101',
    DesktopTuttiListingMiddle1: '102',
    DesktopTuttiListingMiddle2: '103',
    DesktopTuttiListingMiddle3: '104',
    DesktopTuttiListingMiddle4: '105',
    DesktopMotoriListingMiddle: '111',
    DesktopMotoriListingMiddle1: '112',
    DesktopMotoriListingMiddle2: '113',
    DesktopMotoriListingMiddle3: '114',
    DesktopMotoriListingMiddle4: '115',
    DesktopMarketListingMiddle: '121',
    DesktopMarketListingMiddle1: '122',
    DesktopMarketListingMiddle2: '123',
    DesktopMarketListingMiddle3: '124',
    DesktopMarketListingMiddle4: '125',
    DesktopRealEstateListingMiddle: '131',
    DesktopRealEstateListingMiddle1: '132',
    DesktopRealEstateListingMiddle2: '133',
    DesktopRealEstateListingMiddle3: '134',
    DesktopRealEstateListingMiddle4: '135',
    DesktopJobsListingMiddle: '141',
    DesktopJobsListingMiddle1: '142',
    DesktopJobsListingMiddle2: '143',
    DesktopJobsListingMiddle3: '144',
    DesktopJobsListingMiddle4: '145',
    DesktopTuttiListingBottom: '106',
    DesktopMotoriListingBottom: '116',
    DesktopMarketListingBottom: '126',
    DesktopRealEstateListingBottom: '136',
    DesktopJobsListingBottom: '146',
    DesktopTuttiListingZeroResults: '107',
    DesktopMotoriListingZeroResults: '117',
    DesktopMarketListingZeroResults: '127',
    DesktopRealEstateListingZeroResults: '137',
    DesktopJobsListingZeroResults: '147',

    MsiteTuttiListingTop: '150',
    MsiteMotoriListingTop: '160',
    MsiteMarketListingTop: '170',
    MsiteRealEstateListingTop: '180',
    MsiteJobsListingTop: '190',
    MsiteTuttiListingMiddle: '151',
    MsiteMotoriListingMiddle: '161',
    MsiteMarketListingMiddle: '171',
    MsiteRealEstateListingMiddle: '181',
    MsiteJobsListingMiddle: '191',
    MsiteTuttiListingBottom: '152',
    MsiteMotoriListingBottom: '162',
    MsiteMarketListingBottom: '172',
    MsiteRealEstateListingBottom: '182',
    MsiteJobsListingBottom: '192',
    MsiteTuttiListingZeroResults: '153',
    MsiteMotoriListingZeroResults: '163',
    MsiteMarketListingZeroResults: '173',
    MsiteRealEstateListingZeroResults: '183',
    MsiteJobsListingZeroResults: '193',

    DesktopMotoriDetailBottom: '210',
    DesktopMarketDetailBottom: '220',
    DesktopRealEstateDetailBottom: '230',
    DesktopJobsDetailBottom: '240',
    MsiteMotoriDetailBottom: '260',
    MsiteMarketDetailBottom: '270',
    MsiteRealEstateDetailBottom: '280',
    MsiteJobsDetailBottom: '290',
  },
};

const LISTING_DESKTOP = [Channels.Page.Listing, Channels.Property.Desktop];
const LISTING_MSITE = [Channels.Page.Listing, Channels.Property.Msite];
const ADDETAIL_DESKTOP = [Channels.Page.AdDetail, Channels.Property.Desktop];
const ADDETAIL_MSITE = [Channels.Page.AdDetail, Channels.Property.Msite];

export {
  Channels,
  LISTING_MSITE,
  LISTING_DESKTOP,
  ADDETAIL_MSITE,
  ADDETAIL_DESKTOP,
};
