import {
  ExpandSearchItem,
  GalleryCTAItem,
  GalleryPlaceholder,
  ListingPlugin,
} from '@shared/models';
import { all } from '@shared/models/ListingPlugins/matchers';
import {
  endOfListing,
  galleryPosition,
} from '@shared/models/ListingPlugins/position';
import { desktopAdvEvery3 } from '@tools/listingPlugins/desktopAdvEvery3';
import { msiteAdvEvery4 } from '@tools/listingPlugins/msiteAdvEvery4';

const plugins: ListingPlugin[] = [
  {
    model: new GalleryPlaceholder(),
    categories: all(),
    position: galleryPosition(),
  },
  {
    model: new GalleryCTAItem(),
    categories: all(),
    position: galleryPosition(),
    addToListing: (_, galleryCount) => galleryCount > 0,
  },
  {
    model: new ExpandSearchItem(),
    categories: all(),
    position: endOfListing(),
    addToListing: (_, __, searchAdsCount) => searchAdsCount < 5,
  },
];

/**
 * Function providing an array containing all the plugins that should be added to the listing.
 */
export const providePlugins = (): ListingPlugin[] => [
  ...plugins,
  // new setup "ADV every 3" for desktop
  ...desktopAdvEvery3,
  // new setup "ADV every 4" for msite
  ...msiteAdvEvery4,
];
